import React, { useEffect, useState } from 'react';

import { CSVLink } from "react-csv";
import { HiSave } from "react-icons/hi";

import '../../styles/pacientesPendientes.css'
import '../../styles/table.css'

import { savePrecios } from '../../objects/prices';
import { pacientesStore } from '../../firebase/config';
const CredentialViewer = ({identificador,setNomHospital,setTime,time,isLoader,setIsLoader,setIsRutaValida,nomHospital}) => {


const [servicio,setServicio] = useState(0)
const [pricesList, setPricesList] = useState([])
const [valorBusqueda,setValorBusqueda] = useState("")
const [seMuestra,setSeMuestra] = useState(true)
const [resultadoBusqueda, setResultadoBusqueda] = useState([])

const styleActive = { color: "white", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}

const locales = 'en-US'

const options = {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
}

const formatterDolar = new Intl.NumberFormat(locales, options)

const headers = [
    { label: "Unidad", key: "hospital"},
    { label: "Código", key: "patientname"},
    { label: "Rubro", key: "id"},
    { label: "Descripción", key: "birthdate"},
    { label: "Precio", key: "patientid"}
]

const csvreport = {
    data: resultadoBusqueda,
    headers: headers,
    filename: 'Listado de precios '+nomHospital+'.csv',
};


const handleCancelarClick = () => (event) => {
    event.preventDefault()
    setValorBusqueda("")
}

useEffect(()=>{
    if(identificador){
        switch(identificador){
            case "1":
                setServicio(2)
                setNomHospital("Hospital MAC Celaya")
                setIsRutaValida(true)
                break
            case "2":
                setServicio(2)
                setNomHospital("Hospital MAC Aguascalientes Sur")
                setIsRutaValida(true)
                break
            case "3":
                setServicio(2)
                setNomHospital("Hospital MAC Irapuato")
                setIsRutaValida(true)
                break
            case "4":
                setServicio(1)
                setNomHospital("Hospital MAC Guadalajara")
                setIsRutaValida(true)
                break
            case "5":
                setServicio(1)
                setNomHospital("Hospital MAC San Miguel Allende")
                setIsRutaValida(true)
                break
            case "6":
                setServicio(1)
                setNomHospital("Hospital MAC Puebla")
                setIsRutaValida(true)
                break
            case "8":
                setServicio(4)
                setNomHospital("Hospital MAC Mexicali")
                setIsRutaValida(true)
                break
            case "9":
                setServicio(2)
                setNomHospital("Hospital MAC Periferico Sur")
                setIsRutaValida(true)
                break
            case "10":
                setServicio(2)
                setNomHospital("Hospital MAC Aguascalientes Norte")
                setIsRutaValida(true)
                break
            case "12":
                setServicio(1)
                setNomHospital("Hospital MAC Mochis")
                setIsRutaValida(true)
                break
            case "13":
                setServicio(4)
                setNomHospital("Hospital MAC Tampico")
                setIsRutaValida(true)
                break
            case "18":
                setServicio(3)
                setNomHospital("Hospital MAC León")
                setIsRutaValida(true)
                break
            case "19":
                setServicio(3)
                setNomHospital("Hospital HMas Querétaro")
                setIsRutaValida(true)
                break
            case "20":
                setServicio(3)
                setNomHospital("Hospital HMas Los Cabos")
                setIsRutaValida(true)
                break
            case "21":
                setServicio(3)
                setNomHospital("Hospital MAC La Viga")
                setIsRutaValida(true)
                break
            case "40":
                setServicio(1)
                setNomHospital("Hospital MediMAC Tlalnepantla")
                setIsRutaValida(true)
                break
            case "41"://fallo
                setServicio(1)
                setNomHospital("Hospital MediMAC Boca del Rio")
                setIsRutaValida(true)
                break
            case "42":
                setServicio(1)
                setNomHospital("MediMAC Merida")
                setIsRutaValida(true)
                break
            case "43":
                setServicio(1)
                setNomHospital("MediMAC Guanajuato")
                setIsRutaValida(true)
                break
             case "44":
                setServicio(1)
                setNomHospital("MediMAC Cuemanco")
                setIsRutaValida(true)
                break
            case "45":
                setServicio(1)
                setNomHospital("MediMAC Ecatepec")
                setIsRutaValida(true)
                break
            default:
                setServicio(0)
                setIsRutaValida(false)
                break
    
          }
    }
},[identificador,setNomHospital,setIsRutaValida])

useEffect(()=>{
    if(pricesList.length > 0){
        if(valorBusqueda){
            let resultados = []
            pricesList.forEach(
                prices => {
                  let id = prices.id
                  if(!id){
                    id = "SR"
                  }
                  let birthdate = prices.birthdate
                  if(!birthdate){
                    birthdate = "SR"
                  }


                  if(id.toUpperCase().includes(valorBusqueda.toUpperCase())
                        || birthdate.toUpperCase().includes(valorBusqueda.toUpperCase())){
                            resultados.push(prices)
                    }
                }
            )

            if(resultados.length > 0){
                setResultadoBusqueda(resultados)
            }
            else{
                setResultadoBusqueda([])
            }
        }
        else{
            setResultadoBusqueda(pricesList)
        }
    }
    else{
        setResultadoBusqueda([])
    }
},[valorBusqueda,pricesList])

useEffect(()=>{
    if(servicio !== 0){
        if(identificador){
            //setIsLoader(true)
            setTime("")
                if(servicio.toString() === "1" || servicio.toString() === "2" || servicio.toString() === "3" || servicio.toString() === "4"){
                    getPricesSah()
                }
                else{
                    getPrices()
                }
                async function getPrices() {
                    try{
                        const response = await fetch("https://apibot.hospitalesmac.app/public/prices/"+identificador+"/5fe72104fac6af0fa95dbbb0e910fde7");
                        const json = await response.json();
                        json.sort(compararInformación('id'))
                        //setIsLoader(false)
                        let now = new Date()
                        // setTime(now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2,'0') + "-" + now.getDate().toString().padStart(2,'0')
                        // + " " + now.getHours().toString().padStart(2,'0') + ":" + now.getMinutes().toString().padStart(2,'0') + ":" + now.getSeconds().toString().padStart(2,'0'))
                        let fecha = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2,'0') + "-" + now.getDate().toString().padStart(2,'0')
                        + " " + now.getHours().toString().padStart(2,'0') + ":" + now.getMinutes().toString().padStart(2,'0') + ":" + now.getSeconds().toString().padStart(2,'0')

                        if(servicio === "2" || servicio === 2){
                            setTime(fecha)
                            setPricesList(json)
                            setIsLoader(false)
                        }
                        else{
                            //solicitarGuardarPrecios()
                            setTime(fecha)
                            setPricesList(json)
                            setIsLoader(false)
                        }
                        //solicitarGuardarPrecios()
                        async function solicitarGuardarPrecios(){
                            const response = await savePrecios(identificador,fecha,json)
                            if(response === "success"){
                            }
                            else{
                                setTime(fecha)
                                setPricesList(json)
                            }
                        }
                    }
                    catch{

                    }
                }

                async function getPricesSah() {
                    try{
                        //console.log("http://34.125.36.37:3003/prices/"+identificador)
                        let data = {'unidad' : identificador}
                        //const response = await fetch("http://34.125.36.37:3003/prices/"+identificador);
                        const response = await fetch("https://us-west4-auto-factura-erps.cloudfunctions.net/getPrices", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(data),
                        });
                        const json = await response.json();
                        json.sort(compararInformación('id'))
                        //setIsLoader(false)
                        let now = new Date()
                        // setTime(now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2,'0') + "-" + now.getDate().toString().padStart(2,'0')
                        // + " " + now.getHours().toString().padStart(2,'0') + ":" + now.getMinutes().toString().padStart(2,'0') + ":" + now.getSeconds().toString().padStart(2,'0'))
                        let fecha = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2,'0') + "-" + now.getDate().toString().padStart(2,'0')
                        + " " + now.getHours().toString().padStart(2,'0') + ":" + now.getMinutes().toString().padStart(2,'0') + ":" + now.getSeconds().toString().padStart(2,'0')

                        setTime(fecha)
                        setPricesList(json)
                        setIsLoader(false)
                    }
                    catch{

                    }
                }
        }
    }

    const compararInformación = (attr) =>{
        return function (a,b) {
        return comparar(attr,a,b);
        }
    }
    
    function comparar(attr, obj1, obj2) {
        return obj1[attr].localeCompare(obj2[attr]);
    }
},[servicio,identificador,setTime,setIsLoader])


    return(
        <>
        <section className='seccion_pacientes_pendientes'>
            <div className="text-container">
                <input type="text" 
                    name="valorBusqueda" 
                    value={valorBusqueda} 
                    className='inputbuscardirectorio' 
                    placeholder="Búsqueda abierta aquí..."
                    onChange={(event) => setValorBusqueda(event.target.value)}
                />
            </div>
            <br/>
            <div className="Table">
                <div className="Heading">
                    <div className="Cell">
                        <p>Código</p>
                    </div>
                    <div className="Cell">
                        <p>Rubro</p>
                    </div>
                    <div className="Cell">
                        <p>Descripción</p>
                    </div>
                    <div className="Cell">
                        <p>Precio</p>
                    </div>
                </div>
                {
                    !isLoader
                        ?
                            resultadoBusqueda.map((event, index) => {
                                return (
                                    <div className="Row" key={index}>
                                        <div className="Cell">
                                            <p>{event.patientname}</p>
                                        </div>
                                        <div className="Cell">
                                            <p>{event.id}</p>
                                        </div>
                                        <div className="Cell">
                                            <p>{event.birthdate}</p>
                                        </div>
                                        <div className="Cell">
                                            <p>{formatterDolar.format(event.patientid)}</p>
                                        </div>
                                    </div>
                                );
                            })
                        :
                            <div className="Row" key={"Loader"}>
                                <div className="Cell">
                                    <p>{""}</p>
                                </div>
                                <div className="Cell">
                                    <p>{"Cargando"}</p>
                                </div>
                                <div className="Cell">
                                    <p>{"Precios"}</p>
                                </div>
                                <div className="Cell">
                                    <p>{""}</p>
                                </div>
                            </div>
                }
            </div>
            <CSVLink {...csvreport} className="btn-flotanteExport" title="Exportar a CSV" alt="Exportar"><HiSave style={styleActive}/></CSVLink>
        </section>
        </>
    )
}

export default CredentialViewer;
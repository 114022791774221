import React, { useEffect, useState } from 'react';
/**
 * Estilos
 */
import './App.css';


/**
 * Pages
 */
//Page asignada al control de pacientes pendientes de salida de quirófano
import PacientesSalidaQuirofano from './UI_Handler/pages/pacientesSalidaQuirofano';


/**
 * Contenedor general del sitio
 * @returns Devuelve el contenido del sitio
 */
const App = () => {

  //Estado que almacena el identificador del medico deseado
  const [identificador, setIdentificador] = useState("")
  const [isRutaValida, setIsRutaValida] = useState(false)
  const [isLoader,setIsLoader] = useState(true)
  

  useEffect(()=>{
    //let pathname = window.location.pathname
    //console.log(window.location)
    //console.log(window.location.search.split('=')[1])
    //console.log(pathname)
    if(window.location.search.split('=').length ===2){
      setIdentificador(window.location.search.split('=')[1])
      setIsRutaValida(true)
    }
    else{
      setIdentificador("")
      setIsRutaValida(false)
    }
  },[])

  return (
    <div className="App">
      {
        isRutaValida
        ?
          <PacientesSalidaQuirofano identificador={identificador} setIsLoader={setIsLoader} isLoader={isLoader} setIsRutaValida={setIsRutaValida}/>
        :
          <div>
            <h1>Sin informacion de busqueda</h1>
          </div>
      }
    </div>
  );
}

export default App;

import React from 'react';

import '../../styles/footer.css'
/**
 * Componente que contiene los elementos relacionados al footer del sitio
 * @returns Component Devuelve Devuelve el componente con los elementos requeridos
 */
const Footer = () => {

        return(
            <footer className='footer'>
                <div className='maxwidth'>
                <p className='textp3'>© 2022 Derechos reservados HospitalesMAC <br/><a href='https://hospitalesmac.com/privacidad.php' target="_blank" rel='noreferrer'>Aviso de Privacidad, Términos y condiciones</a></p>
                    <a href="https://hospitalesmac.com" target="_blank" rel='noreferrer'>
			            <img src="https://hospitalesmac.com/img/logo_blanco.png" alt="Mac" height="50px" className="logofooter"/>
                    </a>
                    <p className='textp4'>contacto@hospitalesmac.com <br/>versión: 1-0-3<br/></p>     
                </div>
            </footer>
        )
 }
 export default Footer;
import React, { useEffect, useState } from 'react';

/**
 * Componentes
 */
//Componente que contiene los elementos del Header del sitio
import Header from '../components/header';
//Componente que contiene los elementos del Footer del sitio
import Footer from '../components/footer';
import CredentialViewer from '../components/credentialViewer';

/**
 * Servicios
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore, surgeryroomCollection } from '../../firebase/config';

/**
 * Page asignada al control de pacientes pendientes de salida de quirófano
 * @param {Object} param0 Recibe los objetos necesarios para guardar la información de sesión
 * @returns Component Devuelve el sitio con los elementos y funcionalidad asignada
 */
const PacientesSalidaQuirofano = ({identificador,setIsLoader,isLoader,setIsRutaValida}) => {

    //Estado que almacena si esta activa la opción usuarios del menu
    const [isMostrarNuevo,setIsMostrarNuevo] = useState(false)
    //Estado que almacena los pacientes obtenidos desde la base de datos en una lista
    const [pacientesList,setPacientesList] = useState([])

    const [nomHospital,setNomHospital] = useState("")
    const [time, setTime] = useState("")

    useEffect(()=>{
        pacientesStore.collection(surgeryroomCollection)
        .onSnapshot(snap => {
            const pacientes = []
            snap.forEach(doc => {
                pacientes.push({ id: doc.id, ...doc.data() })
            })
            setPacientesList(pacientes)
            setIsMostrarNuevo(true)
        },(error)=>{
            setPacientesList([])
            window.location.reload()
        })
    },[])

    return(
        <>
            <audio>
                <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
            </audio>
            <div id="seccionCabeceraLogin" className='seccionCabeceraLogin'>
                <Header nomHospital={nomHospital} time={time} isLoader={isLoader}/>
            </div>
            <br />
            <div className='cuerpoPacientes'>
            {
                isMostrarNuevo
                ?   
                    <CredentialViewer identificador={identificador} pacientesList={pacientesList} setNomHospital={setNomHospital} 
                    setTime={setTime} time={time} isLoader={isLoader} setIsLoader={setIsLoader} setIsRutaValida={setIsRutaValida} nomHospital={nomHospital}/>
                :
                    <div>
                        <h1>Cargando</h1>
                    </div>
            }
            <Footer/>
            </div>
        </>
    )
}

export default PacientesSalidaQuirofano;
//Importación SDK firebase
import firebase from 'firebase/compat/app';
//Importación del servicio de firestore
import 'firebase/compat/firestore';

import {getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import {getAuth, signInWithEmailAndPassword  } from 'firebase/auth';

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTHDOMAI,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
  };
  
//Inicialización del servicio de firebase
const app = firebase.initializeApp(firebaseConfig);
const storage = getStorage(app)

const auth = getAuth();

signInWithEmailAndPassword(auth, process.env.REACT_APP_FBACCOUNT, process.env.REACT_APP_FBPASS)
  .then((userCredential) => {
    // Signed in
    //const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    //const errorCode = error.code;
    //console.log(errorCode)
    //const errorMessage = error.message;
    //console.log(errorMessage)
  });

// createUserWithEmailAndPassword(auth, process.env.REACT_APP_FBACCOUNT, process.env.REACT_APP_FBPASS)
//   .then((userCredential) => {
//     // Signed in 
//     const user = userCredential.user;
//     console.log(user)
//     // ...
//   })
//   .catch((error) => {
//     console.log(error)
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // ..
//   });


//Exportar el espacio de almacenamiento de base de datos firestore
export const pacientesStore = firebase.firestore();
//console.log(pacientesStore)

//Exportar el identificador de la colección de usuarios
export const usersCollection = 'users'

//Exportar el identificador de la colección de unidades hospitalarias
export const uhCollection = 'unidadesservicios'

//Exportar el identificador de la colección de pacientes en quirófano
export const surgeryroomCollection = 'smmac'

export const padronMedico = 'medicalregistry'

//Exportar el identificador de la colección de log de actividades
export const logCollection = 'pricelog'

//Exportar el identificador de la colección de building (unidades hospitalarias)
export const building = 'building'

//Exportar el identificador de la colección de los resources asignados a un building
export const resources = 'resources'

//export const handleSenMessage = "http://192.168.17.59:3001/HandleSenMessage"
export const handleSenMessage = "https://us-west4-auto-factura-erps.cloudfunctions.net/surgeryroom-notificationsahout"

//Exportar el identificador de la colección de ips
export const ipwhitelist = 'ipwhitelist'

export const saveFile = async (file,ruta) => {
  try{
    const storageRef = ref(storage,ruta)
    uploadBytes(storageRef, file).then((snapshot) => {
      return "success"
    });
  }
  catch (error){
    return error
  }
}

export async function getFile(ruta){
  const storageRef = ref(storage,ruta)
  const url = await getDownloadURL(storageRef)
  return url
}
/**
 * Servicios
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore } from '../firebase/config';

export const savePrecios = async (unidad,fecha,precios) => {
    try {
        await pacientesStore
        .collection("precios")
        .doc(unidad)
        .update({
            unidad:             unidad,
            fecha:            fecha,
            precios:             precios
        })
        return "success"
      } catch (error) {
        return error
      }
}
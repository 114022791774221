import React from "react";
import '../../styles/header.css'


/**
 * Componente que contiene los elementos relacionados al encabezado del sitio
 * @returns Component Devuelve el componente con los elementos requeridos
 */
/**
 * Componente que contiene los elmentos relacionados al encabezado del sitios
 * @param {Object} param0 Recibe los objetos necesarios para mostrar la información de sesión
 * @returns Component Devuelve el componente con los elementos requeridos
 */
const Header = ({access,nomHospital,name,uhList,userLevel,time,isLoader}) => {
    
    return(
        <header className='header'>
            <div className='maxwidth'>
                <div className='datosheader_2'>
                     <a href="http://sm.hospitalesmac.com">
                        <img src="https://hospitalesmac.com/img/logo.png" title="Mac Hospitales" alt="Mac Hospitales" height="45px;" className="logo"/>
                        <div className='maxwidth'>
                            <h2 className='bienvenido-salida-quirofano'><span className="hospitalName"> {"LISTA DE PRECIOS  " + nomHospital} </span></h2>
                        </div>
                        {
                            !isLoader
                                ?
                                    <div className='maxwidth'>
                                        <h2 className='bienvenido-salida-quirofano'><span className="messageTime">Precios actualizados al: {time} Los precios pueden ser modificados sin previo aviso</span></h2>
                                    </div>
                                :
                                    null
                        }
                     </a>                        
                 </div>
            </div>
        </header>
     )
}

export default Header;